import React from 'react'
import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  UndoOutlined,
  RedoOutlined
} from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
  REDO_COMMAND
} from 'lexical'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  ListNode
} from '@lexical/list'

const LowPriority = 1

const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const [isBold, setIsBold] = React.useState(false)
  const [isItalic, setIsItalic] = React.useState(false)
  const [isUnderline, setIsUnderline] = React.useState(false)

  const [isBulletList, setIsBulletList] = React.useState(false)
  const [isNumberedList, setIsNumberedList] = React.useState(false)

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))

      // Check for lists
      const anchorNode = selection.anchor.getNode()
      const focusNode = selection.focus.getNode()
      const anchorListParent = $getNearestNodeOfType(anchorNode, ListNode)
      const focusListParent = $getNearestNodeOfType(focusNode, ListNode)

      setIsBulletList(
        Boolean(
          anchorListParent &&
            anchorListParent.getListType() === 'bullet' &&
            focusListParent &&
            focusListParent.getListType() === 'bullet'
        )
      )

      setIsNumberedList(
        Boolean(
          anchorListParent &&
            anchorListParent.getListType() === 'number' &&
            focusListParent &&
            focusListParent.getListType() === 'number'
        )
      )
    }
  }, [])

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar()
          return false
        },
        LowPriority
      )
    )
  }, [editor, updateToolbar])

  return (
    <div
      className="toolbar"
      style={{
        padding: '8px 12px',
        borderBottom: '1px solid #d9d9d9',
        backgroundColor: '#f5f5f5',
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px'
      }}
    >
      <Space>
        <Tooltip title="Bold">
          <Button
            type={isBold ? 'primary' : 'text'}
            icon={<BoldOutlined />}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
            }}
            size="small"
          />
        </Tooltip>

        <Tooltip title="Italic">
          <Button
            type={isItalic ? 'primary' : 'text'}
            icon={<ItalicOutlined />}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
            }}
            size="small"
          />
        </Tooltip>

        <Tooltip title="Underline">
          <Button
            type={isUnderline ? 'primary' : 'text'}
            icon={<UnderlineOutlined />}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
            }}
            size="small"
          />
        </Tooltip>

        <Tooltip title="Bullet List">
          <Button
            type={isBulletList ? 'primary' : 'text'}
            icon={<UnorderedListOutlined />}
            onClick={() => {
              if (isBulletList) {
                editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
              } else {
                editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
              }
            }}
            size="small"
          />
        </Tooltip>

        <Tooltip title="Numbered List">
          <Button
            type={isNumberedList ? 'primary' : 'text'}
            icon={<OrderedListOutlined />}
            onClick={() => {
              if (isNumberedList) {
                editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
              } else {
                editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
              }
            }}
            size="small"
          />
        </Tooltip>

        <Tooltip title="Undo">
          <Button
            type="text"
            icon={<UndoOutlined />}
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND, undefined)
            }}
            size="small"
          />
        </Tooltip>

        <Tooltip title="Redo">
          <Button
            type="text"
            icon={<RedoOutlined />}
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND, undefined)
            }}
            size="small"
          />
        </Tooltip>
      </Space>
    </div>
  )
}

export default ToolbarPlugin

import React from 'react'
import { Form, Input, Button, Space, Radio, DatePicker, Flex, Typography, message, Tooltip } from 'antd'
import { PlusCircleOutlined, FileMarkdownOutlined } from '@ant-design/icons'
import { FormConfig, RequirementFormData, FieldConfig, NamePath } from './config-driven-types'
import LexicalEditor from './LexicalEditor'

const { Title } = Typography
const { TextArea } = Input

// Helper function to get a string key from a NamePath
const getKeyFromNamePath = (namePath: NamePath): string => {
  if (Array.isArray(namePath)) {
    return namePath.join('-')
  }
  return String(namePath)
}

// Helper function to extract form content as markdown
const extractFormContentAsMarkdown = (config: FormConfig): string => {
  let markdown = ''

  // Process each section
  config.sections.forEach(section => {
    // Add section title as a header
    if (section.title) {
      markdown += `## ${section.title}\n\n`
    }

    // Process each field in the section
    section.fields.forEach(field => {
      if (field.label) {
        markdown += `### ${field.label}\n\n`
      }

      if (field.placeholder) {
        markdown += `${field.placeholder}\n\n`
      }

      // Handle nested fields in formList
      if (field.type === 'formList' && field.childFields) {
        field.childFields.forEach(childField => {
          if (childField.label) {
            markdown += `#### ${childField.label}\n\n`
          }

          if (childField.placeholder) {
            markdown += `${childField.placeholder}\n\n`
          }
        })
      }
    })
  })

  return markdown
}

// Helper function to render fields based on their type
const renderField = (fieldConfig: FieldConfig) => {
  const {
    type,
    name,
    label,
    placeholder,
    rules,
    options,
    rows,
    component,
    addButtonText,
    removeButtonText,
    childFields,
    direction
  } = fieldConfig

  // Generate a unique key for each field
  const fieldKey = getKeyFromNamePath(name)

  switch (type) {
    case 'input':
      return (
        <Form.Item key={fieldKey} name={name} label={label} rules={rules}>
          <Input placeholder={placeholder} />
        </Form.Item>
      )
    case 'textarea':
      return (
        <Form.Item key={fieldKey} name={name} label={label} rules={rules}>
          <TextArea rows={rows || 4} placeholder={placeholder} />
        </Form.Item>
      )
    case 'lexicalEditor':
      return (
        <Form.Item
          key={fieldKey}
          name={name}
          label={label}
          rules={rules}
          getValueFromEvent={value => value}
          getValueProps={value => ({ value })}
        >
          <LexicalEditor
            placeholder={placeholder}
            height={rows ? rows * 24 + 40 : 150} // Base height plus toolbar
          />
        </Form.Item>
      )
    case 'treeSelect':
      return (
        <Form.Item key={fieldKey} name={name} label={label} rules={rules}>
          {component ? React.createElement(component) : <div>Component not provided</div>}
        </Form.Item>
      )
    case 'checkboxGroup':
      return (
        <Form.Item key={fieldKey} name={name} label={label} rules={rules}>
          {component ? React.createElement(component, { options }) : <div>Checkbox group component not provided</div>}
        </Form.Item>
      )
    case 'radioGroup':
      return (
        <Form.Item key={fieldKey} name={name} label={label} rules={rules}>
          <Radio.Group>
            <Space direction={direction || 'vertical'}>
              {options?.map(option => (
                <Radio key={option.value.toString()} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      )
    case 'datePicker':
      return (
        <Form.Item key={fieldKey} name={name} label={label} rules={rules}>
          <DatePicker placeholder={placeholder} className="w-full" />
        </Form.Item>
      )
    case 'formList':
      if (!childFields || childFields.length === 0) return null

      return (
        <Form.Item key={fieldKey} label={label}>
          <Form.List name={name}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name: fieldName }) => (
                  <div key={key} style={{ display: 'flex', marginBottom: 0 }}>
                    {childFields.map(childField => {
                      // Create a namePath for the child field
                      const childNamePath = [
                        fieldName,
                        typeof childField.name === 'string' ? childField.name : String(childField.name)
                      ]

                      // Create a new child field config with the modified name
                      const modifiedChildField: FieldConfig = {
                        ...childField,
                        name: childNamePath
                      }

                      const childKey = `${key}-${getKeyFromNamePath(childField.name)}`

                      return (
                        <div key={childKey} style={{ flex: 1, marginRight: 8 }}>
                          {renderField(modifiedChildField)}
                        </div>
                      )
                    })}
                    <Button type="dashed" onClick={() => remove(fieldName)} danger style={{ marginTop: 4 }}>
                      {removeButtonText || 'Remove'}
                    </Button>
                  </div>
                ))}
                <Form.Item>
                  <Button type="link" onClick={() => add()} icon={<PlusCircleOutlined />}>
                    {addButtonText || 'Add'}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      )
    case 'custom':
      if (!component) return null
      return (
        <Form.Item key={fieldKey} name={name} label={label} rules={rules}>
          {React.createElement(component, fieldConfig)}
        </Form.Item>
      )
    default:
      return null
  }
}

interface ConfigurableFormProps {
  config: FormConfig
  form: any // Form instance
  onFinish: (values: RequirementFormData) => void
  formData?: RequirementFormData // Initial form data
}

const ConfigurableForm: React.FC<ConfigurableFormProps> = ({ config, form, onFinish, formData }) => {
  // Set initial form values if provided
  React.useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [form, formData])

  // Function to copy form structure as markdown to clipboard
  const copyFormStructureToClipboard = () => {
    const markdown = extractFormContentAsMarkdown(config)

    // Use the clipboard API to copy the text
    navigator.clipboard
      .writeText(markdown)
      .then(() => {
        message.success('Form structure copied to clipboard as markdown!')
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
        message.error('Failed to copy to clipboard')
      })
  }

  return (
    <>
      <Flex justify="flex-end" style={{ marginBottom: 16 }}>
        <Tooltip title="Markdown">
          <Button icon={<FileMarkdownOutlined />} onClick={copyFormStructureToClipboard}>
            Markdown
          </Button>
        </Tooltip>
      </Flex>

      <Form
        form={form}
        style={{ maxWidth: config.formProps.maxWidth || 'auto' }}
        layout={config.formProps.layout || 'vertical'}
        scrollToFirstError={config.formProps.scrollToFirstError || false}
        onFinish={onFinish}
        requiredMark={config.formProps.requiredMark || false}
        className={config.formProps.className || ''}
      >
        {config.sections.map(section => (
          <React.Fragment
            key={`section-${section.id || section.title || getKeyFromNamePath(section.fields[0]?.name || 'unknown')}`}
          >
            {section.title && <Title level={4}>{section.title}</Title>}
            {section.fields.map(fieldConfig => renderField(fieldConfig))}
          </React.Fragment>
        ))}

        <Flex justify="flex-end" style={{ marginTop: 24 }}>
          <Button loading={config.submitButton.loading} type="primary" htmlType="submit" className="w-full">
            {config.submitButton.text}
          </Button>
        </Flex>
      </Form>
    </>
  )
}

export default ConfigurableForm

/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-bitwise */

/**
 * Process children of a Lexical node
 * @param node The parent node
 * @param indent Current indentation level
 * @returns Combined string of all children
 */
function processChildren(node: any, indent: number): string {
  if (!node.children || !Array.isArray(node.children)) {
    return ''
  }

  return node.children.map((child: any) => processLexicalNode(child, indent)).join('')
}

/**
 * Process a Lexical node and its children
 * @param node The Lexical node to process
 * @param indent Current indentation level for nested lists
 * @returns Jira formatted string
 */
function processLexicalNode(node: any, indent = 0): string {
  if (!node) return ''

  // Handle different node types
  switch (node.type) {
    case 'root':
      return processChildren(node, indent)

    case 'paragraph':
      return `${processChildren(node, indent)}\n\n`

    case 'heading':
      const level = parseInt(node.tag.substring(1), 10) || 1 // h1, h2, etc.
      return `h${level}. ${processChildren(node, indent)}\n\n`

    case 'list':
      return processListNode(node, indent)

    case 'listitem':
      const prefix = node.listType === 'bullet' ? '*' : '#'
      // Add spaces for indentation in nested lists
      const indentation = ' '.repeat(indent * 2)
      return `${indentation}${prefix} ${processChildren(node, indent + 1)}`

    case 'text':
      return formatTextNode(node)

    case 'link':
      const linkText = processChildren(node, indent)
      const url = node.url || ''
      return `[${linkText}|${url}]`

    case 'quote':
      return `{quote}${processChildren(node, indent)}{quote}\n\n`

    case 'code':
      const language = node.language || ''
      return `{code:${language}}${processChildren(node, indent)}{code}\n\n`

    case 'table':
      return processTableNode(node)

    default:
      // Default handling for unknown node types with children
      if (node.children) {
        return processChildren(node, indent)
      }
      return ''
  }
}

/**
 * Format a text node with styling
 * @param node The text node
 * @returns Formatted text string
 */
function formatTextNode(node: any): string {
  let text = node.text || ''

  // Apply text formatting
  if (node.format) {
    // In Lexical, format is a bit flag
    // 1 = bold, 2 = italic, 4 = underline, 8 = strikethrough, 16 = code, etc.

    // Apply bold
    if (node.format & 1) {
      text = `*${text}*`
    }

    // Apply italic
    if (node.format & 2) {
      text = `_${text}_`
    }

    // Apply underline
    if (node.format & 4) {
      text = `+${text}+`
    }

    // Apply strikethrough
    if (node.format & 8) {
      text = `-${text}-`
    }

    // Apply code
    if (node.format & 16) {
      text = `{{${text}}}`
    }
  }

  return text
}

/**
 * Process a list node
 * @param node The list node
 * @param indent Current indentation level
 * @returns Formatted list string
 */
function processListNode(node: any, indent: number): string {
  if (!node.children || !Array.isArray(node.children)) {
    return ''
  }

  // Process each list item
  const listItems = node.children
    .map((child: any) => {
      return processLexicalNode(child, indent)
    })
    .join('\n')

  return `${listItems}\n\n`
}

/**
 * Process a table node
 * @param node The table node
 * @returns Formatted table string
 */
function processTableNode(node: any): string {
  if (!node.children || !Array.isArray(node.children)) {
    return ''
  }

  let tableOutput = ''
  let isHeader = true

  // Process table rows
  for (const row of node.children) {
    if (row.type !== 'tablerow' || !row.children) continue

    // Process cells in the row
    const cells = row.children.filter((cell: any) => cell.type === 'tablecell')
    if (cells.length === 0) continue

    // Process table cells
    const cellContents = cells.map((cell: any) => {
      return processChildren(cell, 0)
    })

    // Add the row
    if (isHeader) {
      tableOutput += `||${cellContents.join('||')}||\n`
      isHeader = false
    } else {
      tableOutput += `|${cellContents.join('|')}|\n`
    }
  }

  return `${tableOutput}\n`
}

/**
 * Process HTML lists into Jira format
 * @param html HTML content
 * @returns Processed HTML with lists converted to Jira format
 */
function processHTMLLists(html: string): string {
  let result = html

  // Replace unordered lists
  result = result.replace(/<ul>([\s\S]*?)<\/ul>/gi, function (match, listContent) {
    // Process each list item
    return listContent.replace(/<li>([\s\S]*?)<\/li>/gi, function (liMatch: any, liContent: string) {
      // Check for nested lists
      if (liContent.includes('<ul>') || liContent.includes('<ol>')) {
        // Replace nested list opens with new line and proper indentation
        liContent = liContent.replace(/<ul>/gi, '\n ')
        liContent = liContent.replace(/<ol>/gi, '\n ')
        // Remove nested list closes
        liContent = liContent.replace(/<\/ul>/gi, '')
        liContent = liContent.replace(/<\/ol>/gi, '')

        // Process nested list items
        liContent = liContent.replace(/<li>([\s\S]*?)<\/li>/gi, ' * $1\n')
      }

      return `* ${liContent.trim()}\n`
    })
  })

  // Replace ordered lists
  result = result.replace(/<ol>([\s\S]*?)<\/ol>/gi, function (match, listContent) {
    // Process each list item
    return listContent.replace(/<li>([\s\S]*?)<\/li>/gi, function (liMatch: any, liContent: string) {
      // Check for nested lists
      if (liContent.includes('<ul>') || liContent.includes('<ol>')) {
        // Replace nested list opens with new line and proper indentation
        liContent = liContent.replace(/<ul>/gi, '\n ')
        liContent = liContent.replace(/<ol>/gi, '\n ')
        // Remove nested list closes
        liContent = liContent.replace(/<\/ul>/gi, '')
        liContent = liContent.replace(/<\/ol>/gi, '')

        // Process nested list items with correct bullet/number style
        if (liContent.includes('<ol>')) {
          liContent = liContent.replace(/<li>([\s\S]*?)<\/li>/gi, ' # $1\n')
        } else {
          liContent = liContent.replace(/<li>([\s\S]*?)<\/li>/gi, ' * $1\n')
        }
      }

      return `# ${liContent.trim()}\n`
    })
  })

  return result
}

/**
 * Convert HTML-like content to Jira markup
 * @param htmlContent HTML-like content from Lexical
 * @returns Jira formatted string
 */
function convertHTMLToJira(htmlContent: string): string {
  if (!htmlContent) return ''

  let jiraContent = htmlContent

  // Handle headings
  jiraContent = jiraContent.replace(/<h1>(.*?)<\/h1>/gi, 'h1. $1\n\n')
  jiraContent = jiraContent.replace(/<h2>(.*?)<\/h2>/gi, 'h2. $1\n\n')
  jiraContent = jiraContent.replace(/<h3>(.*?)<\/h3>/gi, 'h3. $1\n\n')
  jiraContent = jiraContent.replace(/<h4>(.*?)<\/h4>/gi, 'h4. $1\n\n')
  jiraContent = jiraContent.replace(/<h5>(.*?)<\/h5>/gi, 'h5. $1\n\n')
  jiraContent = jiraContent.replace(/<h6>(.*?)<\/h6>/gi, 'h6. $1\n\n')

  // Handle lists - need to process in a special way to handle nesting
  jiraContent = processHTMLLists(jiraContent)

  // Handle text formatting
  jiraContent = jiraContent.replace(/<strong>(.*?)<\/strong>/gi, '*$1*')
  jiraContent = jiraContent.replace(/<b>(.*?)<\/b>/gi, '*$1*')
  jiraContent = jiraContent.replace(/<em>(.*?)<\/em>/gi, '_$1_')
  jiraContent = jiraContent.replace(/<i>(.*?)<\/i>/gi, '_$1_')
  jiraContent = jiraContent.replace(/<u>(.*?)<\/u>/gi, '+$1+')
  jiraContent = jiraContent.replace(/<s>(.*?)<\/s>/gi, '-$1-')
  jiraContent = jiraContent.replace(/<strike>(.*?)<\/strike>/gi, '-$1-')
  jiraContent = jiraContent.replace(/<del>(.*?)<\/del>/gi, '-$1-')
  jiraContent = jiraContent.replace(/<code>(.*?)<\/code>/gi, '{{$1}}')

  // Handle links
  jiraContent = jiraContent.replace(/<a href="(.*?)">(.*?)<\/a>/gi, '[$2|$1]')

  // Handle blockquotes
  jiraContent = jiraContent.replace(/<blockquote>(.*?)<\/blockquote>/gi, '{quote}$1{quote}')

  // Handle code blocks
  jiraContent = jiraContent.replace(/<pre><code>(.*?)<\/code><\/pre>/gi, '{code}$1{code}')
  jiraContent = jiraContent.replace(/<pre><code class="language-(.*?)">(.*?)<\/code><\/pre>/gi, '{code:$1}$2{code}')

  // Handle paragraphs
  jiraContent = jiraContent.replace(/<p>(.*?)<\/p>/gi, '$1\n\n')

  // Remove any remaining HTML tags
  jiraContent = jiraContent.replace(/<[^>]*>/g, '')

  // Clean up newlines
  jiraContent = jiraContent.replace(/\n{3,}/g, '\n\n')

  return jiraContent.trim()
}

/**
 * Enhanced converter for Lexical Editor content to Jira markup
 * Handles bullet points, numbered lists, headings, and more
 */
export function convertLexicalToJira(lexicalContent: string): string {
  if (!lexicalContent) return ''

  // Check if the content is Lexical JSON format
  let parsedContent
  try {
    // Try to parse as JSON
    if (
      typeof lexicalContent === 'string' &&
      lexicalContent.trim().startsWith('{') &&
      lexicalContent.trim().endsWith('}')
    ) {
      parsedContent = JSON.parse(lexicalContent)
    }
  } catch (e) {
    // If parsing fails, treat as plain text or HTML
    console.warn('Failed to parse as Lexical JSON, treating as plain text/HTML')
  }

  // If we have parsed JSON content, process it
  if (parsedContent && parsedContent.root) {
    return processLexicalNode(parsedContent.root)
  }

  // Otherwise, handle as plain text or HTML with basic conversions
  return convertHTMLToJira(lexicalContent)
}

/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
import { FormConfig, RequirementFormData, SectionConfig, FieldConfig } from './config-driven-types'
import { convertLexicalToJira } from './enhanced-lexical-converter'

// Interface for Jira Service Desk Request
interface JiraServiceDeskRequest {
  requestTypeId: string
  requestFieldValues: {
    summary: string
    description: string
    components: { id: string }[]
    [key: string]: any
  }
}

/**
 * Convert form data to Jira Service Desk request using configuration
 * @param formData The form data (any shape)
 * @param formConfig The form configuration
 * @param requestTypeId The Jira request type ID
 * @returns A formatted Jira Service Desk request
 */
export function convertToJiraRequest(
  formData: RequirementFormData,
  formConfig: FormConfig,
  requestTypeId: string
): JiraServiceDeskRequest {
  // Generate summary from name field or default
  const summary = formData.name ? `Requirement: ${formData.name}` : 'New Requirement'

  // Generate description
  const description = generateDescription(formData, formConfig)

  // Extract component if it exists
  const components = formData.component ? [{ id: formData.component }] : []

  return {
    requestTypeId,
    requestFieldValues: {
      summary,
      description,
      components
    }
  }
}

/**
 * Generate the Jira description from form data and configuration
 * @param data Form data
 * @param config Form configuration
 * @returns Formatted description
 */
function generateDescription(data: RequirementFormData, config: FormConfig): string {
  let output = ''

  // Add title using h1 if name exists
  if (data.name) {
    output += `h1. ${data.name}\n\n`
  }

  // Get sections sorted by order property if available
  const sections = getSortedSections(config)

  // Process each section
  sections.forEach((section, index) => {
    // Get the fields in this section that have data
    const fieldsWithData = getFieldsWithData(section, data)
    if (fieldsWithData.length === 0) return

    // Add section title as h2
    const sectionTitle = section.jira?.title || section.title
    if (sectionTitle) {
      output += `h2. ${sectionTitle}\n\n`
    }

    // Process each field in the section
    fieldsWithData.forEach(fieldInfo => {
      const { field, value } = fieldInfo

      // Format the field - always h3 with emoji
      output += `${formatField(field, value, data)}\n\n`
    })

    // Add separator between sections
    if (index < sections.length - 1) {
      output += '----\n\n'
    }
  })

  return output
}

/**
 * Get sections sorted by order property if available
 * @param config Form configuration
 * @returns Sorted sections array
 */
function getSortedSections(config: FormConfig): SectionConfig[] {
  return [...config.sections].sort((a, b) => {
    const orderA = a.jira?.order !== undefined ? a.jira.order : 999
    const orderB = b.jira?.order !== undefined ? b.jira.order : 999
    return orderA - orderB
  })
}

/**
 * Get fields that have data in the form
 * @param section Section configuration
 * @param data Form data
 * @returns Array of field configs and their values
 */
function getFieldsWithData(
  section: SectionConfig,
  data: RequirementFormData
): Array<{ field: FieldConfig; value: any }> {
  const result: Array<{ field: FieldConfig; value: any }> = []

  // Sort fields by order property if available
  const sortedFields = [...section.fields].sort((a, b) => {
    const orderA = a.jira?.order !== undefined ? a.jira.order : 999
    const orderB = b.jira?.order !== undefined ? b.jira.order : 999
    return orderA - orderB
  })

  // Filter fields that have data
  for (const field of sortedFields) {
    const fieldName =
      typeof field.name === 'string'
        ? field.name
        : Array.isArray(field.name)
        ? field.name.join('.')
        : String(field.name)

    const value = getNestedValue(data, fieldName)

    // Skip empty values
    if (isEmpty(value)) continue

    result.push({ field, value })
  }

  return result
}

/**
 * Format a field value according to its configuration
 * @param field Field configuration
 * @param value Field value
 * @param data Complete form data
 * @returns Formatted field string
 */
function formatField(field: FieldConfig, value: any, data: RequirementFormData): string {
  if (isEmpty(value)) return ''

  const label = field.label || getFieldNameAsLabel(field.name)
  const emoji = field.jira?.emoji || getEmojiForField(field)

  // Get formatted value based on field type
  const formattedValue = formatValue(value, field)

  // Always use h3 for field headings
  return `h3. ${emoji} ${label}\n${formattedValue}`
}

/**
 * Format a value based on field type
 * @param value Field value
 * @param field Field configuration
 * @returns Formatted value string
 */
function formatValue(value: any, field: FieldConfig): string {
  if (isEmpty(value)) return ''

  // Format based on field type
  switch (field.type) {
    case 'lexicalEditor':
      return convertLexicalToJira(value)

    case 'formList':
      return formatFormList(value, field)

    case 'checkboxGroup':
      return formatCheckboxGroup(value, field)

    case 'datePicker':
      return formatDate(value)

    case 'radioGroup':
      return formatRadioGroup(value, field)

    default:
      return String(value)
  }
}

/**
 * Format a form list value
 * @param value Form list value
 * @param field Field configuration
 * @returns Formatted string
 */
function formatFormList(value: any[], field: FieldConfig): string {
  if (!Array.isArray(value) || value.length === 0) return ''

  // Format as bulleted list
  return value
    .map(item => {
      if (typeof item === 'string') {
        return `* ${item}`
      }
      if (item && item.link) {
        return `* ${item.link}`
      }
      return `* ${formatObjectValue(item)}`
    })
    .join('\n')
}

/**
 * Format checkbox group value
 * @param value Checkbox values
 * @param field Field configuration
 * @returns Formatted string
 */
function formatCheckboxGroup(value: string[], field: FieldConfig): string {
  if (!Array.isArray(value) || value.length === 0) return ''

  // Format with checkmarks for each option
  if (field.options) {
    return field.options
      .map(option => {
        const isSelected = value.includes(option.value as string)
        const checkmark = isSelected ? '✅' : '❌'
        return `${checkmark} ${option.label}`
      })
      .join('\n')
  }

  // If no options defined, format as a list
  return value.map(item => `* ${item}`).join('\n')
}

/**
 * Format radio group value
 * @param value Selected value
 * @param field Field configuration
 * @returns Formatted string
 */
function formatRadioGroup(value: string, field: FieldConfig): string {
  if (!value) return ''

  // If it's a priority field with known values, add appropriate emoji
  if (field.name === 'priority' || String(field.name).includes('priority')) {
    if (value.toLowerCase().includes('high')) {
      return '🟠 High Priority'
    }
    if (value.toLowerCase().includes('medium')) {
      return '🟡 Medium Priority'
    }
    if (value.toLowerCase().includes('low')) {
      return '🟢 Low Priority'
    }
  }

  // Find label for the selected value
  if (field.options) {
    const option = field.options.find(opt => opt.value === value)
    if (option) {
      return option.label
    }
  }

  return String(value)
}

/**
 * Format a date value
 * @param value Date value
 * @returns Formatted string
 */
function formatDate(value: any): string {
  if (!value) return ''

  // Handle moment or dayjs objects
  if (typeof value === 'object' && value !== null && typeof value.format === 'function') {
    return value.format('DD.MM.YYYY')
  }

  // Handle Date objects
  if (value instanceof Date) {
    return value.toLocaleDateString()
  }

  // Handle string dates
  return String(value)
}

/**
 * Format an object value as text
 * @param obj Object value
 * @returns Formatted string
 */
function formatObjectValue(obj: any): string {
  if (!obj) return ''

  // Handle link objects
  if (obj.link) {
    return obj.link
  }

  // Format as key-value pairs
  return Object.entries(obj)
    .map(([key, value]) => `${getFieldNameAsLabel(key)}: ${value}`)
    .join('\n')
}

/**
 * Check if a value is empty
 * @param value Any value
 * @returns Boolean indicating if value is empty
 */
function isEmpty(value: any): boolean {
  if (value === undefined || value === null) return true
  if (value === '') return true
  if (Array.isArray(value) && value.length === 0) return true
  if (typeof value === 'object' && Object.keys(value).length === 0) return true
  return false
}

/**
 * Convert field name to a human-readable label
 * @param name Field name
 * @returns Human-readable label
 */
function getFieldNameAsLabel(name: any): string {
  if (typeof name !== 'string') {
    if (Array.isArray(name)) {
      name = name.join('.')
    } else {
      name = String(name)
    }
  }

  // Convert camelCase to Title Case
  return name
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str: string) => str.toUpperCase())
    .trim()
}

/**
 * Get an emoji for the field based on its name
 * @param field Field configuration
 * @returns Emoji string
 */
function getEmojiForField(field: FieldConfig): string {
  const fieldName =
    typeof field.name === 'string' ? field.name : Array.isArray(field.name) ? field.name.join('.') : String(field.name)

  const fieldNameLower = fieldName.toLowerCase()

  // Common patterns for emoji selection
  if (fieldNameLower.includes('deadline') || fieldNameLower.includes('date')) return '📅'
  if (fieldNameLower.includes('priority') || fieldNameLower.includes('important')) return '🔔'
  if (fieldNameLower.includes('product')) return '📦'
  if (fieldNameLower.includes('role') || fieldNameLower.includes('user')) return '👥'
  if (fieldNameLower.includes('function') || fieldNameLower.includes('system')) return '⚙️'
  if (fieldNameLower.includes('target') || fieldNameLower.includes('goal')) return '🎯'
  if (fieldNameLower.includes('scenario') || fieldNameLower.includes('case')) return '🔍'
  if (fieldNameLower.includes('required') || fieldNameLower.includes('info')) return '📋'
  if (fieldNameLower.includes('result')) return '✅'
  if (fieldNameLower.includes('related')) return '🔄'
  if (fieldNameLower.includes('default') || fieldNameLower.includes('value')) return '🔧'
  if (fieldNameLower.includes('accept') || fieldNameLower.includes('criteria')) return '✓'
  if (fieldNameLower.includes('success')) return '🎖️'

  // Default emoji
  return '📝'
}

/**
 * Get nested value from object
 * @param obj Object to get value from
 * @param path Path to the value
 * @returns Value at path
 */
function getNestedValue(obj: any, path: string): any {
  if (!obj) return undefined

  const parts = path.split('.')
  let current = obj

  for (const part of parts) {
    if (current === undefined || current === null) return undefined
    current = current[part]
  }

  return current
}

import React, { useEffect, useState } from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { ListItemNode, ListNode } from '@lexical/list'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { EditorState } from 'lexical'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import ToolbarPlugin from './ToolbarPlugin'

interface LexicalEditorProps {
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
  height?: number | string
  autoFocus?: boolean
}

const LexicalEditor: React.FC<LexicalEditorProps> = ({
  placeholder,
  value,
  onChange,
  height = 200,
  autoFocus = false
}) => {
  const [editorState, setEditorState] = useState<string>(value || '')

  // Initial Lexical Editor configuration
  const initialConfig = {
    namespace: 'MyEditor',
    theme: {
      ltr: 'ltr',
      rtl: 'rtl',
      paragraph: 'editor-paragraph',
      quote: 'editor-quote',
      heading: {
        h1: 'editor-heading-h1',
        h2: 'editor-heading-h2',
        h3: 'editor-heading-h3'
      },
      text: {
        bold: 'editor-text-bold',
        italic: 'editor-text-italic',
        underline: 'editor-text-underline'
      },
      list: {
        ul: 'editor-list-ul',
        ol: 'editor-list-ol'
      }
    },
    onError: (error: Error) => {
      console.error(error)
    },
    nodes: [
      HeadingNode,
      QuoteNode,
      ListNode,
      ListItemNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ]
  }

  // Sync external value to editor
  useEffect(() => {
    if (value !== undefined && value !== editorState) {
      setEditorState(value || '')
    }
  }, [value, editorState])

  // Handle editor changes
  const handleEditorChange = (state: EditorState) => {
    state.read(() => {
      // Get the state as JSON for rich text content
      const editorStateJSON = JSON.stringify(state.toJSON())
      setEditorState(editorStateJSON)

      if (onChange) {
        onChange(editorStateJSON)
      }
    })
  }

  return (
    <div
      className="lexical-editor-container"
      style={{
        border: '1px solid #d9d9d9',
        borderRadius: '2px',
        minHeight: height
      }}
    >
      <LexicalComposer initialConfig={initialConfig}>
        <div className="editor-container">
          <ToolbarPlugin />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  className="editor-input"
                  style={{
                    minHeight: typeof height === 'number' ? `${height - 40}px` : height,
                    padding: '0 12px',
                    outline: 'none',
                    overflowY: 'auto'
                  }}
                />
              }
              placeholder={
                <div
                  className="editor-placeholder"
                  style={{
                    position: 'absolute',
                    color: '#bbb',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    left: '12px',
                    userSelect: 'none',
                    pointerEvents: 'none',
                    whiteSpace: 'pre-line' // This will respect newlines
                  }}
                >
                  {placeholder || 'Enter some text...'}
                </div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ListPlugin />
            <LinkPlugin />
            <OnChangePlugin onChange={handleEditorChange} />
            {autoFocus && <AutoFocusPlugin />}
          </div>
        </div>
      </LexicalComposer>
    </div>
  )
}

export default LexicalEditor

import { Checkbox, CheckboxProps, Divider, GetRef } from 'antd'
import { CheckboxGroupProps } from 'antd/es/checkbox'
import React, { forwardRef } from 'react'
import { styled } from 'styled-components'

const CheckboxGroup = Checkbox.Group

const StyledContainer = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
`

type CheckboxGroupRefType = GetRef<typeof CheckboxGroup>

// Remove the generic type parameter since CheckboxGroup expects string values
const CheckAllCheckboxGroup = forwardRef<CheckboxGroupRefType, CheckboxGroupProps>(
  ({ value = [], onChange, options = [], ...props }, ref) => {
    const safeOptions = options || []
    const checkAll = safeOptions.length > 0 && safeOptions.length === value.length
    const indeterminate = value.length > 0 && safeOptions.length > 0 && value.length < safeOptions.length

    const handleChange = (list: string[]) => {
      onChange?.(list)
    }

    const onCheckAllChange: CheckboxProps['onChange'] = e => {
      // Extract values safely with the correct type
      const plainOptions = safeOptions
        .map(option => (typeof option === 'object' ? option.value : String(option)))
        .filter(Boolean)

      handleChange(e.target.checked ? plainOptions : [])
    }

    return (
      <StyledContainer>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          Valitse kaikki
        </Checkbox>
        <Divider />
        <CheckboxGroup ref={ref} {...props} options={safeOptions} value={value} onChange={handleChange} />
      </StyledContainer>
    )
  }
)

export default CheckAllCheckboxGroup

import CheckAllCheckboxGroup from '../CheckAllCheckboxGroup'
import JiraComponentTreeSelect from '../JiraComponentTreeSelect'
import { FormConfig } from './config-driven-types'

// Esimerkki lomakekonfiguraatiosta Jira-muotoiluineen
export const requirementFormConfig: FormConfig = {
  formProps: {
    maxWidth: 780,
    layout: 'vertical',
    requiredMark: 'optional',
    className: 'space-y-6',
    scrollToFirstError: { behavior: 'smooth' }
  },
  sections: [
    // Perustiedot-osio
    {
      id: 'basic-info',
      title: 'Perustiedot',
      jira: {
        title: 'Perustiedot',
        order: 0
      },
      fields: [
        {
          name: 'name',
          label: 'Vaatimuksen nimi',
          type: 'input',
          placeholder: 'Anna vaatimukselle lyhyt ja kuvaava nimi, esim. "Käyttäjän kirjautuminen"',
          rules: [{ required: true, message: 'Syötä nimi' }],
          jira: {
            emoji: '📝',
            order: 1
          }
        },
        {
          name: 'component',
          label: 'Komponentti',
          type: 'treeSelect',
          component: JiraComponentTreeSelect,
          rules: [{ required: true, message: 'Valitse komponentti' }],
          jira: {
            emoji: '🧩',
            order: 2
          }
        },
        {
          name: 'priority',
          label: 'Prioriteetti',
          type: 'radioGroup',
          options: [
            { label: 'Korkea', value: 'high' },
            { label: 'Keskitaso', value: 'medium' },
            { label: 'Matala', value: 'low' }
          ],
          rules: [{ required: true, message: 'Valitse prioriteetti' }],
          jira: {
            emoji: '🔔',
            order: 3
          }
        },
        {
          name: 'deadline',
          label: 'Määräaika',
          type: 'datePicker',
          placeholder: 'Valitse päivämäärä, jolloin vaatimus tulisi olla valmis, esim. 31.12.2025',
          rules: [{ required: true, message: 'Valitse määräaika' }],
          jira: {
            emoji: '📅',
            order: 4
          }
        }
      ]
    },

    // Tuotteet-osio
    {
      id: 'products',
      title: 'Tuotteet',
      jira: {
        title: 'Tuotteet',
        order: 1
      },
      fields: [
        {
          name: 'products',
          label: 'Valitse tuotteet',
          type: 'checkboxGroup',
          component: CheckAllCheckboxGroup,
          options: [
            { label: 'LIGHT', value: 'LIGHT' },
            { label: 'BASIC', value: 'BASIC' },
            { label: 'PRO', value: 'PRO' }
          ],
          rules: [{ required: true, message: 'Valitse vähintään yksi tuote' }],
          jira: {
            emoji: '📦',
            order: 1
          }
        }
      ]
    },

    // Tuotteet-osio
    {
      id: 'roles',
      title: 'Roolit',
      jira: {
        title: 'Roolit',
        order: 2
      },
      fields: [
        {
          name: 'roles',
          label: 'Valitse roolit',
          type: 'checkboxGroup',
          component: CheckAllCheckboxGroup,
          options: [
            { label: 'company-user', value: 'company-user' },
            { label: 'company-admin', value: 'company-admin' },
            { label: 'accountant-user', value: 'accountant-user' },
            { label: 'accountant-admin', value: 'accountant-admin' },
            { label: 'superuser', value: 'superuser' }
          ],
          rules: [{ required: true, message: 'Valitse vähintään yksi rooli' }],
          jira: {
            emoji: '📦',
            order: 1
          }
        }
      ]
    },

    // Toiminnallisuus-osio
    {
      id: 'functionality',
      title: 'Toiminnallisuus',
      jira: {
        title: 'Toiminnallisuus',
        order: 3
      },
      fields: [
        {
          name: 'functionalityDescription',
          label: 'Mitä järjestelmän pitäisi tehdä?',
          type: 'lexicalEditor',
          placeholder:
            'Järjestelmän tulee mahdollistaa sarakkeiden määrittely funktioilla, joilla voidaan suorittaa erilaisia laskentaoperaatioita raportin muiden sarakkeiden tai tietolähteiden pohjalta. Käyttäjä voi valita erilaisia funktiotyyppejä (summa, jakolasku, prosenttiosuus, muutosprosentti, erotus) ja määritellä funktioiden parametrit joustavasti."',
          rows: 10,
          rules: [{ required: true, message: 'Kuvaile toiminnallisuus' }],
          jira: {
            emoji: '⚙️',
            order: 1
          }
        },
        {
          name: 'functionalityTarget',
          label: 'Kenelle tämä toiminnallisuus on tarkoitettu?',
          type: 'lexicalEditor',
          placeholder:
            'Toiminnallisuus on tarkoitettu edistyneille raporttien käyttäjille, erityisesti tilitoimistojen ja yritysten pääkäyttäjille, jotka tarvitsevat mukautettuja laskentoja raporteissaan.',
          rows: 10,
          rules: [{ required: true, message: 'Kuvaile kenelle tämä on tarkoitettu' }],
          jira: {
            emoji: '🎯',
            order: 2
          }
        },
        {
          name: 'functionalityImportance',
          label: 'Miksi tämä toiminnallisuus on tärkeä?',
          type: 'lexicalEditor',
          placeholder:
            'Funktiotyyppisten sarakkeiden joustava määrittely mahdollistaa monipuolisten analyysien tekemisen suoraan raportoinnissa ilman erillisiä laskentavaiheita. Käyttäjät voivat luoda liiketoiminnan kannalta oleellisia tunnuslukuja ja vertailuja helposti ja nopeasti."',
          rows: 10,
          rules: [{ required: true, message: 'Selitä tärkeys' }],
          jira: {
            emoji: '⭐',
            order: 3
          }
        }
      ]
    },

    // Käyttötapaukset-osio
    {
      id: 'use-cases',
      title: 'Käyttötapaukset',
      jira: {
        title: 'Käyttötapaukset',
        order: 4
      },
      fields: [
        {
          name: 'usageScenario',
          label: 'Kuvaile tilanne, jossa tätä toiminnallisuutta käytetään',
          type: 'lexicalEditor',
          placeholder:
            'Yrityksen pääkäyttäjä haluaa vertailla eri kustannuspaikkoja samalla aikavälillä. Hän määrittelee ensimmäiseen sarakkeeseen yrityksen "Rakennus Oy", laskentakohteeksi "Projekti A" ja aikaväliksi kuluvan vuoden. Toiseen sarakkeeseen hän valitsee saman yrityksen, mutta laskentakohteeksi "Projekti B" ja saman aikavälin. Kolmanteen sarakkeeseen hän lisää summa-funktion, jonka parametreina ovat nämä kaksi saraketta, näin saaden projektien yhteenlasketut luvut. Neljänteen sarakkeeseen hän luo jakolasku-funktion, joka laskee ensimmäisen sarakkeen osuuden kolmannesta sarakkeesta, saaden näin projektin prosentuaalisen osuuden kokonaisuudesta."',
          rows: 10,
          rules: [{ required: true, message: 'Kuvaile käyttöskenaario' }],
          jira: {
            emoji: '🔍',
            order: 1
          }
        },
        {
          name: 'userGoal',
          label: 'Mitä käyttäjä haluaa saavuttaa tällä toiminnallisuudella?',
          type: 'lexicalEditor',
          placeholder:
            'Käyttäjä haluaa luoda dynaamisia raportteja, joissa voidaan suoraan tehdä laskentoja (summat, vertailut, tunnusluvut) raportin muiden sarakkeiden pohjalta. Tämä säästää aikaa ja vähentää virheiden mahdollisuutta verrattuna siihen, että laskenta tehtäisiin manuaalisesti raportin ulkopuolella."',
          rows: 10,
          rules: [{ required: true, message: 'Kuvaile käyttäjän tavoite' }],
          jira: {
            emoji: '🏆',
            order: 2
          }
        }
      ]
    },

    // Yksityiskohdat-osio
    {
      id: 'details',
      title: 'Yksityiskohdat',
      jira: {
        title: 'Tekniset yksityiskohdat',
        order: 5
      },
      fields: [
        {
          name: 'requiredInfo',
          label: 'Vaaditut tiedot (mitä käyttäjä syöttää)',
          type: 'lexicalEditor',
          placeholder:
            'Listaa tiedot, jotka käyttäjän täytyy antaa, esim. "Käyttäjätunnus, salasana ja sähköpostiosoite."',
          rows: 10,
          rules: [{ required: true, message: 'Listaa vaaditut tiedot' }],
          jira: {
            emoji: '📋',
            order: 1
          }
        },
        {
          name: 'results',
          label: 'Tulokset (mitä käyttäjä saa)',
          type: 'lexicalEditor',
          placeholder: `
            • Funktioilla lasketut arvot näkyvät selkeästi raportilla
            • Arvot päivittyvät automaattisesti, kun lähtötiedot muuttuvat
            • Mahdolliset virhetilanteet (kuten nollalla jako) ilmoitetaan käyttäjälle selkeästi
            • Laskennalliset sarakkeet toimivat muuten samoin kuin tavalliset sarakkeet (suodatus, järjestäminen, tulostus)
          `,
          rows: 10,
          rules: [{ required: true, message: 'Kuvaile tulokset' }],
          jira: {
            emoji: '✅',
            order: 2
          }
        },
        {
          name: 'relatedFunctionality',
          label: 'Liittyvä toiminnallisuus',
          type: 'formList',
          addButtonText: 'Lisää linkki',
          removeButtonText: 'Poista',
          jira: {
            emoji: '🔄',
            order: 3
          },
          childFields: [
            {
              label: 'Linkki',
              name: 'link',
              type: 'input',
              placeholder:
                'Lisää URL tai viittaus liittyvään toiminnallisuuteen, esim. "https://jira.example.com/REQ-123"',
              rules: [{ required: true, message: 'Linkki vaaditaan' }]
            }
          ]
        },
        {
          name: 'defaultValues',
          label: 'Oletusarvot ja tallennus',
          type: 'lexicalEditor',
          placeholder: `
            • Funktiosarakkeet tallentuvat osana raportin määrittelyä
            • Tallennus säilyttää kaikki sarakkeen asetukset (funktiotyyppi, lähdesarakkeet, muotoilut)
            • Funktiosarakkeet säilyvät oikein raporttipohjan uudelleenavauksessa
            • Tallennettuja funktiosarakkeiden määrityksiä voi käyttää uudelleen eri raporteissa
          `,
          rows: 10,
          rules: [{ required: true, message: 'Anna oletusarvot' }],
          jira: {
            emoji: '🔧',
            order: 4
          }
        }
      ]
    },

    // Hyväksymiskriteerit-osio
    {
      id: 'acceptance-criteria',
      title: 'Hyväksymiskriteerit',
      jira: {
        title: 'Hyväksymiskriteerit',
        order: 6
      },
      fields: [
        {
          name: 'acceptanceCriteria',
          label: 'Miten tiedät, että toiminnallisuus vastaa tarpeitasi?',
          type: 'lexicalEditor',
          placeholder: `
            • Käyttäjä pystyy luomaan kaikki määritellyt funktiotyypit (summa, jakolasku, prosenttiosuus, muutosprosentti, erotus)
            • Käyttäjä pystyy viittaamaan muihin sarakkeisiin, tunnuslukuihin ja kaavoihin funktioiden parametreina
            • Funktioiden laskenta toimii oikein erilaisilla parametreilla
            • Funktiosarakkeiden arvot päivittyvät automaattisesti, kun lähdesarakkeiden arvot muuttuvat
            • Funktiosarakkeet säilyvät oikein raporttipohjan tallennuksen jälkeen
            • Käyttöliittymä on intuitiivinen ja helppo käyttää myös vähemmän teknisille käyttäjille
            • Järjestelmä ohjaa käyttäjää rakentamaan vain säännöllisen kielen mukaisia sallittuja rakenteita
            • Järjestelmä estää virheellisten rakenteiden muodostamisen ja antaa selkeät virheilmoitukset
          `,
          rows: 10,
          rules: [{ required: true, message: 'Anna hyväksymiskriteerit' }],
          jira: {
            emoji: '✓',
            order: 1
          }
        },
        {
          name: 'successCriteria',
          label: 'Miltä onnistunut lopputulos näyttää?',
          type: 'lexicalEditor',
          placeholder:
            'Käyttäjä voi luoda monipuolisia raportteja, joissa on automaattisia laskentoja, vertailuja ja tunnuslukuja. Funktiosarakkeiden avulla voidaan luoda esimerkiksi budjettiseurantaraportteja, jotka näyttävät poikkeamat, trendianalyysejä tai kannattavuusvertailuja eri yksiköiden välillä. Käyttäjä ei tarvitse erillisiä taulukkolaskentaohjelmia analyysien tekemiseen, vaan kaikki voidaan tehdä suoraan raportointijärjestelmässä. Käyttöliittymä ohjaa käyttäjää älykkäästi valitsemään oikeat parametrit oikeassa järjestyksessä, jolloin virheiden määrä vähenee ja raporttien rakentaminen on tehokasta.',
          rows: 10,
          rules: [{ required: true, message: 'Kuvaile onnistunut lopputulos' }],
          jira: {
            emoji: '🎖️',
            order: 2
          }
        }
      ]
    }
  ],
  submitButton: {
    text: 'Lähetä vaatimus',
    loading: false
  }
}

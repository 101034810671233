import { Company } from '../../../types/company/Company'
import { Dimension } from '../../../types/dimension/Dimension'
import { BudgetingScenario } from '../../scenario/types/BudgetingScenario'
import { JiraIssueComponent, JiraServiceDeskRequest, JiraServiceDeskRequestFormFields } from '../types'

export function buildComponentTree(data?: JiraIssueComponent[]): any[] {
  const root: any = { value: 'null', title: '/', children: [] }

  data
    ?.slice()
    .sort((a, b) => (a.description || '').localeCompare(b.description || '', 'en'))
    .forEach(component => {
      const pathSegments = component.description?.split('/').filter(Boolean) // Split and remove empty segments
      let currentNode = root
      let fullPath = '' // To track the full path

      pathSegments?.forEach((segment, index) => {
        fullPath += `/${segment}` // Build the full path progressively

        if (!currentNode.children) {
          currentNode.children = []
        }

        // Check if the node already exists
        let newNode = currentNode.children.find((child: any) => child.title === fullPath)
        if (!newNode) {
          newNode = {
            value: component.description === fullPath ? component.id : fullPath, // Only set value for leaf nodes
            title: fullPath,
            selectable: index === pathSegments.length - 1,
            ...(index === pathSegments.length - 1 ? {} : { children: [] }) // Add `children` only for non-leaf nodes
          }
          currentNode.children.push(newNode)
        }

        currentNode = newNode
      })
    })

  return root.children || []
}

interface TreeSelectOptionType {
  value?: string | number
  title?: React.ReactNode
  label?: React.ReactNode
  key?: React.Key
  children?: TreeSelectOptionType[]
}

export const customFieldTransform = (item?: Dimension | BudgetingScenario | Company): string | undefined => {
  if (!item) return undefined
  return `${item.name} ( ${item.id} )`
}

export const selectOptionTransform = (item: Dimension | BudgetingScenario | Company) => ({
  key: item.id,
  value: customFieldTransform(item),
  label: item.name
})

export const dimensionMap = ({ children, ...o }: Dimension): TreeSelectOptionType => ({
  value: customFieldTransform(o),
  title: o.name,
  children: children?.map(dimensionMap) // recursive call
})

/**
 * Transforms Jira Service Desk request form fields into a request object.
 * @param values - The Jira Service Desk request form fields
 * @returns Transformed Jira Service Desk request object
 */
// FIXME: components comes as a single value but needs to be sent as an array, check if Jira integration needs adjustment
export const transformFields = (values: JiraServiceDeskRequestFormFields): JiraServiceDeskRequest => {
  const data = {
    ...values,
    requestFieldValues: {
      ...values.requestFieldValues,
      components: values?.requestFieldValues?.components ? [{ id: values.requestFieldValues.components }] : undefined
    }
  }
  return data
}

import { InboxOutlined } from '@ant-design/icons'
import { UploadFile, Upload } from 'antd'
import { t } from 'i18next'
import React from 'react'

interface UploadAttachmentProps {
  files: UploadFile[]
  setFiles: (files: UploadFile[]) => void
}

const UploadAttachment: React.FC<UploadAttachmentProps> = ({ files, setFiles }) => {
  const options = {
    name: 'file',
    multiple: true,
    fileList: files,
    onRemove: (item: UploadFile) => {
      setFiles(files.filter(f => f.name !== item.name))
    },
    beforeUpload: (file: UploadFile, allFiles: UploadFile[]) => {
      setFiles([...files, ...allFiles])
      return false
    }
  }

  return (
    <Upload.Dragger {...options}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t('jiraRequestModal:upload')}</p>
      <p className="ant-upload-hint">{t('jiraRequestModal:uploadInfo')}</p>
    </Upload.Dragger>
  )
}

export default UploadAttachment

import React, { useState } from 'react'
import { Form, message } from 'antd'
import ConfigurableForm from './requirement/ConfigurableForm'
import { RequirementFormData } from './requirement/config-driven-types'
import { convertToJiraRequest } from './requirement/simplified-jira-formatter'
import { requirementFormConfig } from './requirement/config'
import { createJiraRequest } from '../service/jiraService'

// Import your form configuration
// This is just a placeholder - you should import your actual configuration

const RequirementForm: React.FC = () => {
  const [form] = Form.useForm<RequirementFormData>()
  const [loading, setLoading] = useState(false)

  // Create a copy of the config to update the loading state
  const formConfig = {
    ...requirementFormConfig,
    submitButton: {
      ...requirementFormConfig.submitButton,
      loading
    }
  }

  const handleSubmit = async (values: RequirementFormData) => {
    setLoading(true)
    try {
      // Convert form data to Jira request
      const jiraRequest = convertToJiraRequest(values, formConfig, '9')

      // Log for demonstration
      console.log('Form values:', values)
      console.log('Jira request:', jiraRequest)

      const jiraFormData = new FormData()
      jiraFormData.append('data', JSON.stringify(jiraRequest))
      await createJiraRequest(jiraFormData)

      message.success('Requirement submitted successfully')
      form.resetFields()
    } catch (error) {
      console.error('Error submitting form:', error)
      message.error('Failed to submit requirement')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="requirement-form-container">
      <ConfigurableForm config={formConfig} form={form} onFinish={handleSubmit} />
    </div>
  )
}

export default RequirementForm

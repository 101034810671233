import React, { forwardRef } from 'react'
import { GetRef, TreeSelect, TreeSelectProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { useUseJiraComponents } from '../queries/useJiraComponents'
import { buildComponentTree } from '../utils/utils'

type SelectRefType = GetRef<typeof TreeSelect>

const JiraComponentTreeSelect = forwardRef<SelectRefType, TreeSelectProps>((props, ref) => {
  const { t } = useTranslation()
  const { data: jiraComponents, isFetching: jiraComponentsFetching } = useUseJiraComponents()

  return (
    <TreeSelect
      ref={ref}
      loading={jiraComponentsFetching}
      treeDefaultExpandAll
      treeData={buildComponentTree(jiraComponents)}
      treeTitleRender={item => {
        return t(`menu:${item.title}`)
      }}
      {...props}
    />
  )
})

export default JiraComponentTreeSelect
